@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    background: linear-gradient(to right, black, #1f2f3a);
    width: 100vw;
    min-height: 100vh;
    padding: 15px;
}

::-webkit-scrollbar {
    width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
    background: black
  }
  
/* Handle */
::-webkit-scrollbar-thumb {
    background: black;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgb(116, 199, 255);
}

.glass {
    position: relative;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }

  